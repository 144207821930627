import React, { useState, useEffect } from 'react';
import moment from 'moment';
import api from 'api';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Header, PolicyMenu, ConfirmDialog } from 'components';
import {
  Container,
  ContentArea,
  Title,
  PolicyInformationArea,
  ItemPolicy,
  Subtitle,
  Text,
  LoadingArea,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  Card,
} from './styles';
import { BsShieldFillCheck, BsShieldFillX, BsShieldFillExclamation } from 'react-icons/bs';
import { formatCPF, formatPlate, formatCNPJ } from 'react-data-formatter';
import { toast } from 'react-toastify';
import { Alert } from '@material-ui/lab';
import { formatCurrency } from 'react-data-formatter';
import { success, error, warn, loadingColor } from 'styles/colorProvider';
import { useHistory } from 'react-router-dom';
import { getCardFlag } from 'utils/creditCard';
import { cpf } from 'cpf-cnpj-validator';

function PolicyDetails({ location }) {
  const policyId = parseInt(location.pathname.split('/detalhes-da-apolice/')[1]);
  const history = useHistory();
  const [policyData, setPolicyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [onConfirm, setOnConfirm] = useState(false);
  const [cardActive, setCardActive] = useState(null);
  const [policyInfo, setPolicyInfo] = useState({
    adjustmentFactor: '70%',
    adjustmentFactorValue: 70,
    userBetween18and25Years: false,
    franchiseValue: 'Normal-Obrigatória 10%',
  });

  const cardData = async () => {
    setLoading(true);
    const resp = await api({
      method: 'GET',
      url: `/me/active-card`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });
    setCardActive(resp.data);
    setLoading(false);
  };

  const policyIdData = async () => {
    const policyNumber = {
      method: 'GET',
      url: `/policy/${policyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    };
    setLoading(true);
    const response = await api(policyNumber);

    setPolicyData(response.data);
    setLoading(false);
  };

  const handleChangeAutomaticCharge = async () => {
    setLoading(true);
    try {
      await api({
        method: 'PATCH',
        url: `/automatic-charge`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id_policy: policyId,
          automatic_charge: policyData?.automatic_charge ? false : true,
        },
        json: true,
      });
      setLoading(false);
      setShowConfirm(false);
      toast.success('Alterado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      policyIdData();
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  useEffect(() => {
    cardData();
    policyIdData();
  }, [onConfirm]);

  const renderConfirmDialog = () => {
    return typeof cardActive === 'object' ? (
      <ConfirmDialog
        open={showConfirm}
        onCancel={() => setShowConfirm(false)}
        content={`Tem certeza de que deseja ${
          policyData?.automatic_charge ? 'desativar' : 'ativar'
        } a cobrança automática para esta apólice?`}
        title={`${policyData?.automatic_charge ? 'Desativar' : 'Ativar'} a cobrança automática`}
        onConfirm={handleChangeAutomaticCharge}
      />
    ) : (
      <ConfirmDialog
        open={showConfirm}
        onCancel={() => setShowConfirm(false)}
        content={`Você não possui cartão cadastrado, você será direcionado para carteira e basta adicioná-lo`}
        title={`Ativar a cobrança automática`}
        onConfirm={() => history.push('/minha-carteira')}
      />
    );
  };

  useEffect(() => {
    // LMI LOGIC
    const auctionOrRemarked = policyData?.vehicle?.auction || policyData?.vehicle?.remarked;
    // Starts with 70% <- Auction or Remarked
    let adjustmentFactor = '70%';
    let adjustmentFactorValue = 70;

    if (!auctionOrRemarked) {
      const adjustmentsFactors =
        policyData?.TB_PROPOSAL?.TB_QUOTATION_PROPOSAL?.[0]?.TB_PRICING_FIPE_LMI_TB_QUOTATION_PROPOSAL?.filter(
          (item) => item?.active,
        );

      // If has valid LMI or 100%
      adjustmentFactor = adjustmentsFactors?.[0]?.TB_PRICING_FIPE_LMI?.name || '100%';
      adjustmentFactorValue = adjustmentsFactors?.[0]?.TB_PRICING_FIPE_LMI?.lmi || 100;
    }

    // Franchise Logic
    const showFranchiseValue =
      policyData?.TB_PROPOSAL?.TB_QUOTATION_PROPOSAL?.[0]?.TB_PRICING_DEDUCTIBLE_TB_QUOTATION_PROPOSAL?.[0]?.TB_PRICING_DEDUCTIBLE
        ?.enabled;

    const franchiseValue =
      policyData?.TB_PROPOSAL?.TB_QUOTATION_PROPOSAL?.[0]?.TB_PRICING_DEDUCTIBLE_TB_QUOTATION_PROPOSAL?.filter(
        (c) => c?.active,
      )?.[0]?.TB_PRICING_DEDUCTIBLE?.name;
    // Apólices antigas
    const oldFranchiseValue = policyData.coverages?.find((c) => c?.name?.includes('Franquia'))?.name_complete;

    // User Between 18 and 25 years
    const userBetween18and25Years = policyData?.TB_PROPOSAL?.TB_QUOTATION_PROPOSAL?.[0]?.some_driver_18_25_years_old;

    setPolicyInfo({
      ...policyInfo,
      adjustmentFactor,
      adjustmentFactorValue,
      userBetween18and25Years,
      franchiseValue:
        showFranchiseValue && franchiseValue ? franchiseValue : oldFranchiseValue ? oldFranchiseValue : 'Normal-Obrigatória 10%',
    });
  }, [policyData]);
  const isCpf = cpf.isValid(policyData?.user?.cpf);

  return (
    <>
      {renderConfirmDialog()}
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <ContentArea>
            <PolicyMenu policyId={policyId} onConfirm={setOnConfirm} policyData={policyData} />
            <Title>DETALHES DA APÓLICE</Title>
            {policyData?.status === 'active' && policyData?.not_renew === true && (
              <Alert style={{ marginBottom: 20 }} severity="warning">
                Cancelamento solicitado - Sua vigência é válida até o próximo vencimento{' '}
                {moment(policyData.next_due).format('DD/MM/YYYY')}
              </Alert>
            )}
            {policyData?.status === 'active' &&
              policyData?.requested_cancellation === true &&
              policyData?.accepted_cancellation === true && (
                <Alert style={{ marginBottom: 20 }} severity="warning">
                  Sua vigencia é válida até o próximo vencimento, reverta o seu cancelamento!
                </Alert>
              )}

            <PolicyInformationArea>
              <ItemPolicy>
                {policyData?.status === 'active' && policyData?.requested_cancellation === false && (
                  <Subtitle style={{ color: success, textTransform: 'uppercase', marginBottom: 10 }}>
                    <BsShieldFillCheck size={16} style={{ marginRight: 5 }} /> Você está seguro!
                  </Subtitle>
                )}
                {policyData?.status === 'active' &&
                  policyData?.requested_cancellation === true &&
                  policyData?.accepted_cancellation === true && (
                    <Subtitle style={{ color: warn, textTransform: 'uppercase', marginBottom: 10 }}>
                      <BsShieldFillCheck size={16} style={{ marginRight: 5 }} /> Você está seguro!
                    </Subtitle>
                  )}

                {policyData?.status === 'overdue' && (
                  <Subtitle style={{ color: warn, textTransform: 'uppercase', marginBottom: 10 }}>
                    <BsShieldFillExclamation size={16} style={{ marginRight: 5 }} /> Aguardando pagamento!
                  </Subtitle>
                )}

                {policyData?.requested_cancellation === true &&
                  (policyData?.accepted_cancellation === null || policyData?.accepted_cancellation === false) && (
                    <Subtitle style={{ color: warn, textTransform: 'uppercase', marginBottom: 10 }}>
                      <BsShieldFillExclamation size={16} style={{ marginRight: 5 }} /> Aguardando cancelamento!
                    </Subtitle>
                  )}

                {policyData?.status === 'canceled' && (
                  <Subtitle style={{ color: error, textTransform: 'uppercase', marginBottom: 10 }}>
                    <BsShieldFillX size={16} style={{ marginRight: 5 }} /> Você não está seguro!
                  </Subtitle>
                )}

                <Text>
                  Contrato: <span>{policyData?.policy_number}</span>
                </Text>
                <Text>
                  Apólice: <span>{policyData?.code_ebao}</span>
                </Text>
                <Text>
                  Mensalidade:{' '}
                  <span>
                    {policyData?.value_final
                      ? formatCurrency(policyData?.value_final)
                      : policyData?.value
                        ? formatCurrency(policyData?.value * (1 - (policyData?.proposal?.discount_new || 0)))
                        : 0}
                  </span>
                </Text>
                {/* Só mostra os valores quando a página é carregada */}
                <Text>Fator de ajuste LMI: {policyData?.id && <span>{policyInfo?.adjustmentFactor}</span>}</Text>
                <Text>Franquia: {policyData?.id && <span>{policyInfo?.franchiseValue}</span>}</Text>
              </ItemPolicy>

              <ItemPolicy>
                {<Subtitle>Apólice gerada em:</Subtitle>}
                <Text style={{ marginBottom: '1.3rem' }}>{moment.utc(policyData?.created_at).format('DD/MM/YYYY')}</Text>

                {policyData?.status !== 'active' ? <Subtitle>Vencido em</Subtitle> : <Subtitle>Inicio da vigência:</Subtitle>}
                <Text style={{ marginBottom: '1.3rem' }}>
                  {moment
                    .utc(policyData?.expiration_date)
                    .subtract(1, 'months')
                    .set('date', moment.utc(policyData?.created_at).date())
                    .format('DD/MM/YYYY')}
                </Text>

                {policyData?.status !== 'active' ? <Subtitle>Vencido em</Subtitle> : <Subtitle>Fim da vigência:</Subtitle>}
                <Text style={{ marginBottom: '1.3rem' }}>{moment.utc(policyData?.expiration_date).format('DD/MM/YYYY')}</Text>

                {policyData?.status !== 'active' ? <Subtitle>Vencido em</Subtitle> : <Subtitle>Próximo vencimento:</Subtitle>}
                <Text style={{ marginBottom: '1.3rem' }}>{moment.utc(policyData?.next_due).format('DD/MM/YYYY')}</Text>

                <Subtitle>Cobrança automática:</Subtitle>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                  <Text style={{ margin: '0 1px 0 0' }}>{policyData?.automatic_charge ? 'Ativa' : 'Inativa'}</Text>
                </div>

                {policyData?.automatic_charge && cardActive && (
                  <Card>
                    <Text>
                      Cartão:
                      <span> **** **** **** {cardActive?.last_four_digits}</span>
                    </Text>
                    <div>{getCardFlag(cardActive?.last_four_digits, cardActive?.brand).image}</div>
                  </Card>
                )}
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Informações do segurado</Subtitle>
                <Text>
                  Nome do segurado: <span>{policyData?.user?.name}</span>
                </Text>
                {isCpf && (
                  <Text>
                    Nome social: <span>{policyData?.user?.social_name}</span>
                  </Text>
                )}
                <Text>
                  CPF: <span>{formatCPF(policyData?.user?.cpf)}</span>
                </Text>
                {isCpf && (
                  <Text>
                    Data de nascimento: <span>{moment(policyData?.user?.birthdate).utcOffset(0).format('DD/MM/YYYY')}</span>
                  </Text>
                )}
              </ItemPolicy>
              {policyData?.TB_PARTNER_STIPULATING && (
                <ItemPolicy>
                  <Subtitle>Informações do estipulante</Subtitle>
                  <Text>
                    Nome do estipulante: <span>{policyData?.TB_PARTNER_STIPULATING?.name}</span>
                  </Text>
                  <Text>
                    CNPJ: <span>{formatCNPJ(policyData?.TB_PARTNER_STIPULATING?.document)}</span>
                  </Text>
                  <Text>
                    Endereço: <span>{policyData?.TB_PARTNER_STIPULATING?.address}</span>
                  </Text>
                  <Text>
                    CEP: <span>{policyData?.TB_PARTNER_STIPULATING?.zipcode}</span>
                  </Text>
                </ItemPolicy>
              )}
              <ItemPolicy>
                <Subtitle>Informações do condutor principal</Subtitle>
                <Text>
                  Nome: <span>{policyData?.TB_PROPOSAL?.maindriver_name || policyData?.user?.name}</span>
                </Text>
                <Text>
                  CPF: <span>{formatCPF(policyData?.TB_PROPOSAL?.maindriver_document || policyData?.user?.cpf)}</span>
                </Text>
                {isCpf && (
                  <Text>
                    Data de nascimento:{' '}
                    <span>
                      {moment(policyData?.TB_PROPOSAL?.maindriver_birthdate || policyData?.user?.birthdate)
                        .utcOffset(0)
                        .format('DD/MM/YYYY')}
                    </span>
                  </Text>
                )}
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Informações do veículo</Subtitle>
                <Text>
                  Marca e modelo: <span>{`${policyData?.vehicle?.brand}/ ${policyData?.vehicle?.model}`}</span>
                </Text>
                <Text>
                  Placa:{' '}
                  <span>{policyData?.vehicle?.plate === null ? 'Sem placa' : formatPlate(policyData?.vehicle?.plate)}</span>
                </Text>
                <Text>
                  Ano e modelo: <span>{`${policyData?.vehicle?.year_model} / ${policyData?.vehicle?.manufacture_year}`}</span>
                </Text>
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Coberturas</Subtitle>
                {policyData?.products?.length &&
                  policyData?.products
                    .filter((coverage) => coverage.isService === false)
                    .map((coverage) => <Text>• {coverage.name} </Text>)}
                {policyData?.coverages?.length &&
                  policyData?.coverages
                    .filter((product) => product.isService === false)
                    .map((product) => <Text>• {product.name} </Text>)}
                {policyData?.arrayUnique?.length &&
                  policyData?.arrayUnique
                    .filter((array) => array.isService === false)
                    .map((array) => <Text>• {array.name} </Text>)}
                <Subtitle style={{ paddingTop: '10px' }}>Serviços</Subtitle>
                {policyData?.products?.length &&
                  policyData?.products
                    .filter((coverage) => coverage.isService === true)
                    .map((coverage) => <Text>• {coverage.name} </Text>)}
                {policyData?.coverages?.length &&
                  policyData?.coverages
                    .filter((product) => product.isService === true)
                    .map((product) => <Text>• {product.name} </Text>)}
                {policyData?.arrayUnique?.length &&
                  policyData?.arrayUnique
                    .filter((array) => array.isService === true)
                    .map((array) => <Text>• {array.name} </Text>)}
              </ItemPolicy>
            </PolicyInformationArea>
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default PolicyDetails;
